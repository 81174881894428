import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from "react-icons/fa";

function Contact() {
  return (
    
    <div className='contact' id='contactId'>
      
      <div className='left-contact'>
        .contact.
        <br/>
       
        <a href='https://www.linkedin.com/in/mina-h-891b07101/'><AiIcons.AiFillLinkedin /></a>
        <a href='https://open.spotify.com/user/1253120000'><FaIcons.FaSpotify /></a>

      </div>
      <div className='about-circ'>
        {/* <img src={Img} /> */}
      </div>
      <div className='right-contact'>
        
        <form action="https://formspree.io/f/xvollpve" method="POST">
        <label>
        Your email:
        <br/>
        <input type="email" name="email" placeholder='email here' required/>
        </label>
        <br/>
        <br/>
        <label>
        Your message:
        <br/>
        <textarea name="message" placeholder='type message here' required></textarea>
        </label>
        <br/>
        <button type="submit">Send</button>
        </form>
      </div>
    </div>
  )
}

export default Contact