import React from 'react';
import Doodle from '../img/end.png';

function Footer() {
  return (
    <div className='footer'>
      <div className='foot-text'>
        This site is a work in progress, thank you for visiting!
    
      </div>
      <img src={Doodle}></img>
    </div>
  )
}

export default Footer