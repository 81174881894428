import { Parallax } from "react-parallax";
import Doodle from '../img/concept1.png'

const ImageThree = () => (
    <Parallax className='image2' blur={0} bgImage={Doodle} strength={300}>
        <div className='content-2'>
            <span className='img-txt'>
            <div className='passions-2'>
            a song i enjoy:
            </div>
            </span>
            <div className="video">
                <iframe
                    width="213"
                    height="120"
                    src={`https://www.youtube.com/embed/jsezr0qiFIc`}
                    frameBorder="10"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
        </div>
    </Parallax>
)

export default ImageThree;