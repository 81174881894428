import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from 'react-icons/ai';
import * as GiIcons from 'react-icons/gi';
import * as CgIcons from 'react-icons/cg';

export const ProjectsData = [
    {
        title: ' pokemon card collection tracker ',
        icon: <CgIcons.CgPokemon />,
        cName: 'proj-label',
        desc: 'A website to track one\'s TCG collection with additional fun features utilizing the PokemonTCG Api. Currently in progress'
    },
    {
        title: ' amaris: realm of dreams',
        icon: <GiIcons.GiConsoleController />,
        cName: 'proj-label',
        desc:  'A puzzle platformer. The project utilizes LibGDX for development in Java, and I created game assets/animations with a team.'
    },
    {
        title: ' news popularity classifier',
        icon: <AiIcons.AiOutlineBarChart />,
        cName: 'proj-label',
        desc: 'A report on methods to predict news popularity. Trained/evaluated several models on UCIs News Popularity dataset.'
    },
    {
        title: ' learning analytics dashboard',
        icon: <AiIcons.AiOutlineDashboard />,
        cName: 'proj-label',
        desc: 'A dashboard based on clicker data. Used Figma for prototyping and R for insights displayed using RShiny.'
    }
 
];