
import './App.css';

import ImageOne from './components/ImageOne';
import ImageTwo from './components/ImageTwo';
import ImageThree from './components/ImageThree';
import Footer from './components/Footer';
import Navbar from './components/Navigation';

// import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import About from './pages/About';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
// import Cool from './pages/Cool';
// import { Link, animateScroll as scroll} from 'react-scroll';

function App() {
  return (
    <div className='app-bckg'>
      <Navbar/>
      <ImageOne/>
      <About /> 
      <ImageTwo/>
      <Projects /> 
      <ImageThree/>
      <Contact /> 
      {/* <ImageThree/> */}
      {/* <Cool/> */}
      <Footer/>
      
    </div>
    // <Router>
    //   <Navbar/>
    //   <Routes>
    //     <Route path='/' exact element={<Home/>}/>
    //     <Route path='/about' element={<About/>}/>
    //     <Route path='/projects' element={<Projects/>}/>
    //     <Route path='/cool' element={<Cool/>}/>
    //     <Route path='/contact' element={<Contact/>}/>
    //   </Routes>
    // </Router>
  );
}

export default App;
