import { Parallax } from "react-parallax";
import Doodle from '../img/concept2.png';
import { Link } from 'react-scroll';



const ImageOne = () => (
    <Parallax className='image' blur={0} bgImage={Doodle} strength={300} >
        <div className='content'>
            
            <span className='img-txt'>
                <div>Hello :)</div>
                <br/>
                <br/>
                <div className='para'>my name is Mina Huh<br/>
                welcome to my site!
                
                <div className="button">
                <Link className= 'button-text' activeClass='active' to='aboutId' spy={true}
                    smooth={true} duration={500}>
                        Get to Know Me 
                </Link>
                </div>
                </div> 
                </span>
                <div className='circle'></div>
        </div>
        
    </Parallax>
)

export default ImageOne;