import { Parallax } from "react-parallax";
import Doodle from '../img/concept3.png'
import Slideshow from "./Slideshow";


const ImageTwo = () => (
    <Parallax className='image2' blur={0} bgImage={Doodle} strength={300}>
        <div className='content-2'>
                <div className='passions'>
                I also love art and creative coding, 
                here are some things that inspire me!
                <br/>
                <br/>
                </div>
            
                <div className='one'>an <a href='https://www.instagram.com/y2_kyle/'>artist</a> i really like currently</div>
                <div className='one'><a href='https://nokemon.eloie.tech/'>fake pokemon generator!</a></div>
                <div className='one'>a <a href='https://elderly-stoat-dd2.notion.site/Resource-List-e0492ad36a174d9d98f11cbdf0a0f46e'>doc</a> i'm compiling fun links in</div>
            {/* <ul>
                <li>
                <a href="https://nokemon.eloie.tech/">fake pokemon generator</a>
                </li>
                <li>
                <a href="https://www.youtube.com/watch?v=ylLlGVE01Bo">食品まつり a.k.a Foodman - Clock feat. machìna</a>
                </li>
                <li>
                <a href="https://www.y2kyle.com/">y2kyle</a>
                </li>
                
            </ul> */}
            {/* <div className="video">
                <iframe
                    width="213"
                    height="120"
                    src={`https://www.youtube.com/embed/ylLlGVE01Bo`}
                    frameBorder="10"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div> */}
            {/* <Slideshow/> */}
            
        </div>
    </Parallax>
)

export default ImageTwo;