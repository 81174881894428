import React from 'react';
import { Link } from 'react-scroll';
import Img from '../img/img_me.png';

function About() {
  return (

    <div className='about' id='aboutId'>
      <div className= 'about-label'>.about me.</div>
      <div className='about-circ'>
        {/* <img src={Img} /> */}
      </div>
      <p className='about-content'> I'm a recent grad with a love for collaborative learning. Some of my interests include
      <br/>
      <br/>
      <ul>
        <li>Full-stack programming</li>
        <li>Machine Learning and ethics</li>
        <li>Human-Computer Interaction</li>
        <li>Tech accessibility</li>
        {/* <li>Creative coding </li> */}

      </ul>  
      <br/>
       Always down to talk ideas, feel free to 
       <Link className='contact-me' activeClass='active' to='contactId' spy={true}
                    smooth={true} duration={500}>
                        contact me!
        </Link></p>
    </div>
  );
}

export default About