import React from 'react';
import { Link } from 'react-scroll';
import * as AiIcons from 'react-icons/ai';

const Navbar = () => {
    return(
        <div className='navBar'>
            <ul>
                <li >
                    <Link activeClass='active' to='aboutId' spy={true}
                    smooth={true} duration={500}>
                        .about. 
                    </Link>
                </li>
                <li >
                    <Link activeClass='active' to='projectsId' spy={true}
                    smooth={true} duration={500}>
                        .projects. 
                    </Link>
                </li>
                <li >
                    <Link activeClass='active' to='contactId' spy={true}
                    smooth={true} duration={500}>
                         .contact. 
                    </Link>
                </li>
                {/* <li >
                    <Link activeClass='active' to='coolId' spy={true}
                    smooth={true} duration={500}>
                        .. cool! 
                        
                    </Link>
                </li> */}
                
                
            </ul>
        </div>
    )
}


export default Navbar;