import React, {useState, useEffect} from 'react';
import './Slideshow.css';



function Slideshow({images=[], interval=3000}) {

    const [thumbnails, setThumbnails] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentSlideStyle, setCurrentSlideStyle] = useState({});

    useEffect(()=>{
        setThumbnails(images);
        setCurrentSlideStyle({
            backgroundImage: "url('"+images[currentSlide]+"')"
        });
    }, [images, currentSlide]);

    function previous(){
        if(currentSlide>0){
            setCurrentSlide(currentSlide-1);
        }else{
            setCurrentSlide(thumbnails.length-1);
        }
    }

    function next(){
        if(currentSlide === thumbnails.length-1){
            setCurrentSlide(0);
        }else{
            setCurrentSlide(currentSlide+1);
        }
    }
    
  return (
    <section className="slideshow">
        <div className="slide-holder">
        <section className="slide previous">
            <div className="slide-thumbnail"></div>
        </section>
        <section className="slide current">
            <div style={currentSlideStyle} className="slide-thumbnail"></div>
        </section>
        <section className="slide next">
            <div className="slide-thumbnail"></div>
        </section>
        </div>          
        <div className="slideshow-controller">
        <span onClick={previous}>Prev</span>
        <span onClick={next}>Next</span>
        </div>
    </section>
  )
}

export default Slideshow;