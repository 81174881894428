import React from 'react';
import { ProjectsData } from './ProjectsData';

function Projects() {
  return (
    <div className='projects' id='projectsId'>
      <div className='projects-content' >
        .projects.
        <br/>
        <div className='proj-container'>
        {ProjectsData.map((item, index) => {
                    return(
                        
                        <li key={index} className={item.cName}>
                              
                                <div className='ind-proj'>
                                <div className='proj-name'>
                                {/* {"--> "} */}
                                {item.icon}
                                {item.title}</div>
                               
                                <p>{item.desc}</p>
                                </div>
                                
                            
                            
                        </li>
                        
                        
                        
                        
                    
                    );
                })}
          </div>
               
      </div>
    </div>
  )
}

export default Projects